.cardContainer{
    display: flex;
    width: 100%;
    justify-content: center;
}
.cardContainerList{
    margin: 0 3px;
}
.marginVertical{
    list-style-position: inside;
    margin: 20px 0;
}