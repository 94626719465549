#logincss{
    display: block;
    top: 50%;
    left: 50%; 
    transform: translate(25%, 0%); 
}

@media only screen and (max-width: 992px) {
    #logincss{
        top: 0%;
        left: 0%; 
        transform: none; 
    }
     
}