.hero_header {
    position: relative;
    background-color: black;
    height: 75vh;
    min-height: 90vh;
    width: 100%;
    overflow: hidden;
  }
  
  .hero_header video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
  
  .hero_header .container {
    position: relative;
    z-index: 2;
  }
  
  .hero_header .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 1;
  }
  .hero-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    color: white;
  }